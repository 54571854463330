.project-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.project-modal {
  background-color: $background-color;
  padding: 2em;
  position: relative;
  border-radius: 8px;
  max-width: 70%;
  max-height: 80vh;
  overflow-y: auto;
  animation: grow 0.3s ease-out;

  .close {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
    font-size: 1.5em;
    color: $text-color;
  }

  .image-carousel {
    position: relative;
    max-width: 100%;
    height: 400px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .prev, .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      color: $text-color;
      font-size: 2em;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: $primary-color;
      }
    }

    .prev {
      left: 0.5em;
    }

    .next {
      right: 0.5em;
    }
  }

  .project-tags {
    display: flex;
    gap: 0.5em;
    margin: 1em 0;

    .tag {
      color: #888;
      font-size: 0.9em;
    }
  }

  h2 {
    font-size: 1.8em;
    margin-top: 0.5em;
    color: $text-color;
  }

  p {
    color: $text-color;
    margin-top: 0.5em;
  }

  .project-links {
    margin-top: 1em;

    a {
      margin-right: 1em;
      padding: 0.5em 1em;
      color: white;
      background-color: $primary-color;
      border-radius: 4px;
      text-decoration: none;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $secondary-color;
      }
    }
  }
}

@keyframes grow {
  from { transform: scale(0.8); }
  to { transform: scale(1); }
}
