.header {
  display: flex;
  align-items: center; // Center content vertically within the viewport
  justify-content: space-between; // Keep three-column layout
  padding-top: 0;
  height: 100vh;

  .header-section {
    flex: 1;
    padding: 20px;

    &.left, &.right {
      text-align: center;
      align-self: center;

      h1, h2, h3 {
        margin: 0.5em 0;
      }

      h1 {
        font-size: 75px;
        font-weight: bold;
        line-height: 1.2;
      }

      h2 {
        font-size: 75px;
        font-weight: bold;
        line-height: 1.2;
      }

      h3 {
        font-size: 24px;
        line-height: 1.5;

        &.occupation {
          background: linear-gradient(to right, #11998E, #38EF7D);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &.middle {
      display: flex;
      flex-direction: column; // Stack profile photo and socials vertically
      align-items: center; // Center horizontally
      justify-content: center; // Center vertically in the middle column

      .profile-photo {
        width: 350px;
        height: 350px;
        // border: 3px solid $primary-color;
        clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
        transition: transform 0.3s;
        object-fit: cover;

        &:hover {
          transform: scale(1.05);
        }
      }

      .social-icons {
        display: flex;
        justify-content: center;
        gap: 1em;
        margin-top: 1em;
      }
    }

    &.right {
      text-align: center;
      align-self: center;

      p {
        font-size: 24px;
        line-height: 1.5;
        margin: 0.5em 0;
      }
    }
  }

  // Responsive adjustments
  @media (max-width: 1024px) {
    .header-section {
      padding: 15px;

      h1, h2 {
        font-size: 80px;
      }

      h3, p {
        font-size: 20px;
      }

      .profile-photo {
        width: 200px;
        height: 200px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    .header-section {
      padding: 10px 0;

      h1, h2 {
        font-size: 60px;
      }

      h3, p {
        font-size: 18px;
      }

      .profile-photo {
        width: 180px;
        height: 180px;
      }
    }
  }

  @media (max-width: 480px) {
    .header-section {
      padding: 5px 0;

      h1, h2 {
        font-size: 40px;
      }

      h3, p {
        font-size: 16px;
      }

      .profile-photo {
        width: 150px;
        height: 150px;
      }

      .social-icons {
        gap: 0.5em;

        a {
          font-size: 1.2em;
        }
      }
    }
  }
}
