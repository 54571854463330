.portfolio {
    padding: 3em 0;
    text-align: center;
  
    h2 {
      font-size: 3em;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 0.5em;
    }
  
    p {
      color: #cccccc;
      margin-bottom: 1.5em;
    }
  
    .portfolio-gallery {
      display: grid;
      gap: 2em;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      justify-items: center;
      padding: 0 2em;
    }
  }
  