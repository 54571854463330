.project-card {
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease;
  
    &:hover {
      transform: scale(1.05);
    }
  
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  
    .project-info {
      padding: 1em;
  
      h3 {
        margin: 0;
        font-size: 1em;
        color: #333;
      }
  
      p {
        font-size: 0.9em;
        color: #666;
      }
    }
  
    &.small {
      width: 200px;
    }
  
    &.large {
      width: 100%;
      max-width: 600px;
    }
  }
  