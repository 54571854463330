// src/assets/styles/_variables.scss
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$background-color: #111111;
$primary-color: #53e78c;
$secondary-color: #11998E; // Darker green for gradients
$secondary-highlight: #26c0b3;
$header-color: #FFF8F5;
$text-color: #FFFEFD;
$header-font-family: 'Koulen', sans-serif;
$font-family: 'Poppins', sans-serif;
$base-font-size: 16px;

// Gradient for buttons
$button-gradient: linear-gradient(45deg, $primary-color, $secondary-color);

// Responsive breakpoints
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
