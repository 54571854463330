// src/assets/styles/main.scss

@import 'variables';
@import 'mixins';
@import 'navbar';
@import 'header';
@import 'particle-background';
@import 'about-me';
@import 'skills';
@import 'portfolio';
@import 'project-modal';
@import 'project-card';
@import 'contact-form';
@import 'footer';

// Global styles
body {
  margin: 0;
  background-color: $background-color;
  color: $text-color;
  font-family: $font-family;
  font-size: $base-font-size;
  overflow-x: hidden;
}

// Custom scrollbar
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: darken($background-color, 5%);
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 6px;
  border: 3px solid $background-color;
}
