// src/assets/styles/_particle-background.scss

#tsparticles {
    background-color: $background-color;
  
    .particles {
      color: $primary-color;
      &-links {
        color: $primary-color;
      }
    }
  }
  