.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $background-color;
  display: flex;
  justify-content: space-around;
  padding: 1em 0;
  align-items: center;
  opacity: 0.9;
  backdrop-filter: blur(10px);
  z-index: 9;

  h2 {
    flex: 1 1 25%;
    font-family: $header-font-family;
    font-size: 1.5em;
    background: linear-gradient(#FFF, #ffffff00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    text-align: center;
  }

  ul {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;

    li {
      margin: 0 1em;

      a {
        color: $text-color;
        text-decoration: none;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .select-container {
    flex: 1 1 25%;
    display: flex;
    justify-content: center;

    select {
      background: $background-color;
      color: $text-color;
      font-size: 1em; // Increased font size for the dropdown only
      border: 2px solid $primary-color;
      border-radius: 8px;
      padding: 0.5em 1em;
      height: fit-content;
      width: 130px; // Adjusted width to accommodate flags and text
      appearance: none; // Remove default dropdown arrow for consistent styling
      cursor: pointer;
      text-align: center;
      text-align-last: center; // Center text within the dropdown
      transition: background 0.3s ease, border 0.3s ease;

      &:hover,
      &:focus {
        background: lighten($background-color, 10%);
        border-color: $secondary-color;
      }
    }

    select option {
      background: $background-color;
      color: $text-color;
      font-size: 1.2em;
      padding: 0.5em;
      border: none;
      text-align: center;
    }
  }
}
