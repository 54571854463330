.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 2em;
  color: #f8f9fa;
  background-color: #313131;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 2em;
    color: $primary-color;
    text-align: center;
    margin-bottom: 1em;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    label {
      display: flex;
      flex-direction: column;
      font-size: 1em;

      select, input, textarea {
        margin-top: 0.5em;
        padding: 0.75em;
        font-size: 1em;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
        background-color: #cccccc;

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0px 0px 5px rgba($primary-color, 0.3);
        }
      }

      textarea {
        min-height: 120px;
      }
    }

    button {
      padding: 0.75em;
      font-size: 1.1em;
      color: #fff;
      background-color: $primary-color;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: $secondary-color;
      }
    }
  }

  .status-message {
    text-align: center;
    margin-top: 1em;
    padding: 0.5em;
    font-size: 1em;
    border-radius: 4px;

    &.success {
      color: #155724;
      background-color: #d4edda;
      border: 1px solid #c3e6cb;
    }

    &.error {
      color: #721c24;
      background-color: #f8d7da;
      border: 1px solid #f5c6cb;
    }
  }
}
