.about-me {
    display: flex;
    justify-content: center;
    padding: 3em;
    
    .glass-background {
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      width: 90%;
      border-radius: 12px;
      padding: 2em;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;
      transition: height 0.3s ease;
      border: none;
      border-radius: 8px;
  
      .chip {
        background: rgba($primary-color, 0.05); // Inner solid background color
        padding: 0.5em 1.2em;
        border-radius: 20px;
        font-size: 0.9em;
        color: white;
        position: absolute;
        top: 1em;
        left: 50%;
        transform: translateX(-50%);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 2px; // This simulates a 2px border
            border-radius: inherit; // Matches the border-radius of the main element
            background: linear-gradient($primary-color, $secondary-color); // Gradient background for the border
            -webkit-mask: // Creates the inner cutout to avoid covering the content background
              linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            z-index: -1; // Places the pseudo-element behind the content
          }
      }
  
      .title {
        text-align: center;
        font-size: 2.5em;
        font-weight: bold;
        color: #f0f0f0;
      }
  
      .columns {
        display: flex;
        justify-content: space-between;
        gap: 2em;
        margin-top: 1em;
  
        .column {
            position: relative;
            background: rgba($primary-color, 0.05); // Inner solid background color
            border-radius: 12px; // Rounded corners for the inner background
            padding: 1em;
            flex: 1;
            overflow: hidden;
          
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              padding: 2px; // This simulates a 2px border
              border-radius: inherit; // Matches the border-radius of the main element
              background: linear-gradient($primary-color, $secondary-color); // Gradient background for the border
              -webkit-mask: // Creates the inner cutout to avoid covering the content background
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
              -webkit-mask-composite: destination-out;
              mask-composite: exclude;
              z-index: -1; // Places the pseudo-element behind the content
            }
          
          
          h3 {
            font-size: 1.5em;
            color: $primary-color;
            margin-bottom: 1em;
            text-align: left;
          }
  
          .data-row {
            display: grid;
            grid-template-columns: 120px auto;
            grid-template-rows: auto auto;
            gap: 0.3em 2em;
            margin-bottom: 1.2em;
  
            .date {
              grid-column: 1;
              grid-row: 1 / 2;
              font-size: $base-font-size;
              color: $text-color;
              align-self: center;
            }
  
            .title {
              grid-column: 2;
              font-size: $base-font-size * 1.2;
              font-weight: bold;
              color: $text-color;
              text-align: left;
            }
  
            .description {
              grid-column: 2;
              font-size: 1em;
              color: rgba(255, 255, 255, 0.7);
              font-weight: normal;
            }
          }
  
          button {
            margin-top: 1em;
            padding: 0.5em 1em;
            background-color: $primary-color;
            color: white;
            border: none;
            border-radius: 8px;
            cursor: pointer;
  
            &:hover {
              background-color: darken($primary-color, 10%);
            }
          }
        }
      }
    }
  
    // Fade-in animation for the glass container
    &.fade-in {
      animation: fadeIn 0.8s ease-in-out forwards;
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  