.footer {
    padding: 2em 1em;
    background-color: #111; // Dark background for a sleek look
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  
    .footer-nav {
      margin-bottom: 1em;
  
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5em;
        justify-content: center;
  
        li {
          a {
            color: #bbb;
            text-decoration: none;
            font-size: 1em;
            transition: color 0.3s;
  
            &:hover {
              color: $primary-color; // Add hover effect
            }
          }
        }
      }
    }
  
    .additional-info {
      font-size: 0.9em;
      line-height: 1.6;
      color: #bbb;
  
      p {
        margin: 0.5em 0;
      }
  
      a {
        color: #bbb;
        text-decoration: none;
        font-size: 0.9em;
        transition: color 0.3s;
  
        &:hover {
          color: $primary-color; // Match other link hover colors
        }
      }
    }
  
    // Responsive adjustments for smaller screens
    @media (max-width: 600px) {
      .footer-nav ul {
        gap: 1em;
      }
      
      .additional-info {
        font-size: 0.8em;
      }
    }
  }
  