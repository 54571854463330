.skills-container {
    padding: 3em;
    display: flex;
    justify-content: center;

    .skills-content {
      display: flex;
      background: linear-gradient(135deg, $secondary-color, $primary-color);
      border-radius: 16px;
      justify-content: space-between;
      width: 90%;
      padding: 2em;
    }
  
    &.fade-in {
      animation: fadeIn 0.8s ease-in-out forwards;
    }
  
    .skills-left {
      flex: 1;
      padding-right: 4em;
      max-width: 40%;

      .chip {
        background: rgba($secondary-highlight, 0.5);
        display: inline-block;
        padding: 0.5em 1.2em;
        border-radius: 20px;
        font-size: 0.9em;
        color: white;
      }

      h2 {
        font-size: 2.5em;
        font-weight: bold;
        color: white;
        margin-top: 0em;
      }

      p {
        color: #e0e0e0;
        margin-top: 0.5em;
        font-size: 1em;
      }
    }

    .skills-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 2em;

      .skill-container {
        width: 100%;
        margin-bottom: 1.5em;
        display: flex;
        flex-direction: column;

        .skill-row {
          display: flex;
          align-items: center;

          .skill-bar {
            flex: 1;
            display: flex;
            align-items: center;
            background: #333;
            border-radius: 8px;
            padding: 0.4em;
            cursor: pointer;
            transition: background 0.3s ease, box-shadow 0.3s ease;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

            &:hover {
              background: rgba(255, 255, 255, 0.1);
              box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
            }

            .skill-name {
              color: white;
              font-weight: bold;
              margin-left: 1em;
            }
          }

          .skill-level {
            margin-left: 1em;
            color: white;
            font-weight: bold;
          }
        }

        .project-gallery {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            margin-top: 0.5em;
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.3s ease;
          
            &.expanded {
              max-height: 100vh; // Allow full expansion when visible
            }
          
            .project-card {
              background: $background-color;
              padding: 1em;
              border-radius: 8px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              cursor: pointer;
              transition: transform 0.2s ease;
              margin: 10px;
              &:hover {
                transform: scale(1.05);
              }
            }
          }
          
      }

      button {
        margin-top: 1em;
        padding: 0.5em 1em;
        background-color: transparent;
        color: white;
        border: 1px solid white;
        border-radius: 8px;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
